export default [{
    _name: 'CSidebarNav',
    _children: [{
            _name: 'CSidebarNavItem',
            name: 'sidebar.dashboard',
            to: '/dashboard',
            icon: 'cil-speedometer',
            items: [],
            permission: 'dashboard'
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Event Categories',
            icon: 'cil-list',

            to: '/quizs/categories',
            items: [],
            permission: 'quiz-category'

        },
        {
            _name: 'CSidebarNavItem',
            name: 'Deposit',
            icon: 'cil-list',

            to: '/quizs/deposits',
            items: [],
            permission: 'quiz-category'

        },
        {
            _name: 'CSidebarNavItem',
            name: 'Expense',
            icon: 'cil-list',

            to: '/quizs/expenses',
            items: [],
            permission: 'quiz-category'

        },
        // {
        //     _name: 'CSidebarNavItem',
        //     name: 'Setting',
        //     to: '/general-setting/app-update',
        //     permission: 'dashboard',
        //     icon: 'cil-list',
        //     items: []
        // },
        {
            _name: 'CSidebarNavItem',
            name: 'User List',
            to: '/app-management/users',
            icon: 'cil-people',
            items: [],
            permission: 'dashboard'
        },
        // {
        //     _name: 'CSidebarNavDropdown',
        //     name: 'User Management',
        //     route: '/app-users',
        //     icon: 'cil-user',
        //     permission: 'app-user-manage',
        //     items: [
             
        //         {
        //             _name: 'CSidebarNavItem',
        //             name: ' - Admin List',
        //             to: '/app-management/admin-users',
        //             icon: 'cil-people',
        //             items: [],
        //             permission: 'admin-user-list'
        //         },
        //         //   {
        //         //     _name: 'CSidebarNavItem',
        //         //     name: ' - Roles',
        //         //     to: '/app-management/roles',
        //         //     icon: 'cil-list',
        //         //     items: [],
        //         //     permission: 'role-list'
        //         //   },
        //         //   {
        //         //     _name: 'CSidebarNavItem',
        //         //     name: ' - Permissions',
        //         //     to: '/app-management/permissions',
        //         //     icon: 'cil-list',
        //         //     items: [],
        //         //     permission: 'permission-list'
        //         //   }
        //     ]
        // }
    ]
}]